import Vue from 'vue'
import Router from 'vue-router'
import '~/polyfills/reflect-metadata' // Reflection API polyfill required by tsyringe
import { scrollBehavior, setScrollRestoration } from './utils'
import indexRoutes from './routes/plot'
import adminRoutes from '~/router/routes/plot/admin'
import playgroundRoutes from './routes/shared/playground'
import classifiedViewRoutes from './routes/plot/classifieds/view'
import classifiedSearchRoutes from './routes/plot/classifieds/search'
import shortUrlRedirectRoutes from './routes/plot/short-url-redirect'
import compareRoutes from './routes/shared/compare'
import messagesRoutes from './routes/shared/messages'
import adminSharedRoutes from './routes/shared/admin'
import analyticsRoutes from './routes/shared/analytics'
import feedsRoutes from './routes/shared/feeds'
import accountRoutes from './routes/plot/account/'
import listsRoutes from './routes/shared/lists'
import loginRegisterRoutes from './routes/plot/login-register'
import notificationsRoutes from './routes/shared/notifications'
import formRoutes from './routes/plot/classifieds/form'
import editRoutes from './routes/shared/classifieds/edit'
import restoreTouchWithCodeRoutes from './routes/shared/classifieds/restore-touch-with-code'
import shareSettingsRoutes from './routes/shared/settings'
import blacklistRoutes from './routes/plot/blacklist'
import landingPages from './routes/plot/landing-pages'
import sharedLandingPages from './routes/shared/landing-pages'
import informationRoutes from './routes/plot/information'
import { userProfileRoutes } from '~/router/routes/shared/classifieds/user-profile'
import oneClickTouchRoutes from './routes/shared/classifieds/one-click-touch'
import tradesRoutes from './routes/shared/trades.ts'
import priceTrendsRoutes from './routes/plot/price-trends.ts'
import { isProductionEnv } from '~/constants/environment.ts'
import piraeusLoanTerms from './routes/shared/piraeus-loan-terms.ts'
import shortsRoutes from '~/router/routes/shared/shorts.ts'

Vue.use(Router)

setScrollRestoration()

const router = routes =>
  new Router({
    mode: 'history',
    scrollBehavior,
    routes
  })

/**
 *
 * @param serverSideCtx {Context | undefined} This is undefined on the client side.
 * @returns {VueRouter}
 */
export function createRouter(_serverSideCtx) {
  return router([
    ...indexRoutes,
    ...(isProductionEnv ? [] : playgroundRoutes),
    ...messagesRoutes,
    ...editRoutes,
    ...classifiedViewRoutes,
    ...classifiedSearchRoutes,
    ...compareRoutes,
    ...adminSharedRoutes,
    ...analyticsRoutes,
    ...feedsRoutes,
    ...accountRoutes,
    ...listsRoutes,
    ...loginRegisterRoutes,
    ...notificationsRoutes,
    ...formRoutes,
    ...restoreTouchWithCodeRoutes,
    ...shareSettingsRoutes,
    ...adminRoutes,
    ...oneClickTouchRoutes,
    ...shortUrlRedirectRoutes,
    ...userProfileRoutes,
    ...blacklistRoutes,
    ...landingPages,
    ...informationRoutes,
    ...tradesRoutes,
    ...sharedLandingPages,
    ...priceTrendsRoutes,
    ...piraeusLoanTerms,
    ...shortsRoutes
  ])
}
