import { interopDefault } from '~/router/utils'

const BlacklistTooManyClassifieds = () =>
  interopDefault(
    import(
      '~/pages/plot/blacklist/too-many-classifieds.vue' /* webpackChunkName: "pages-blacklist-too-many-classifieds.vue" */
    )
  )

export default [
  {
    name: '__blacklist_too_many_classifieds',
    path: '/too-many-classifieds/',
    component: BlacklistTooManyClassifieds
  }
]
