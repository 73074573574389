import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__redirect_short_url',
    path: '/s/:shortId/',
    component: () =>
      interopDefault(
        import(
          '~/pages/plot/shortRedirect.vue' /* webpackChunkName: "pages-plot-short-url-redirect" */
        )
      )
  }
]
