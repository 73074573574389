





import { ErrorPageError } from '~/models/error'
import {
  defineComponent,
  PropType,
  toRefs,
  useMeta
} from '@nuxtjs/composition-api'
import { useErrorPage } from '~/compositions/error-page'

export default defineComponent({
  props: {
    // NOTE: should not be used directly, use the processedError computed property instead.
    error: {
      type: Object as PropType<ErrorPageError>,
      default: () => {}
    }
  },
  layout: 'error-layout',
  setup(props) {
    const { error: layoutErrorProp } = toRefs(props)
    const { processedError, errorComponent } = useErrorPage(layoutErrorProp)

    useMeta({
      title: `${processedError.value.statusCode} - ${processedError.value.message}`
    })

    return { processedError, errorComponent }
  },
  head: {}
})
