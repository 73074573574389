import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__price-trends',
    path: '/price-trends/',
    component: () =>
      interopDefault(
        import(
          '~/pages/plot/price-trends.vue' /* webpackChunkName: "pages-plot-price-trends.vue" */
        )
      )
  }
]
