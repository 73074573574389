import { interopDefault } from '~/router/utils'

export default [
  {
    name: '__landing_pages_promotions',
    path: '/landing/promotions/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/promotions/index.vue' /* webpackChunkName: "plot-landing-pages-promotions.vue" */
        )
      )
  },
  {
    name: '__landing_pages_plot_app',
    path: '/landing/plot-app/',
    meta: { isLanding: true },
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/landing/plot/index.vue' /* webpackChunkName: "plot-landing-pages-plot-app.vue" */
        )
      )
  }
]
