import { interopDefault } from '~/router/utils'
import { CategoryId } from '~/models/category/types'

export default [
  {
    name: '__classifieds_new',
    path: '/classifieds/new',
    redirect: {
      name: '__classifieds_new_form',
      params: { categoryId: CategoryId.PLOT }
    }
  },
  {
    name: '__classifieds_new_form',
    path: '/classifieds/new/:categoryId',
    component: () =>
      interopDefault(
        import(
          '~/pages/shared/classifieds/form/new.vue' /* webpackChunkName: "pages-classifieds-form-new" */
        )
      ),
    meta: { rootCategoryId: CategoryId.PLOT }
  }
]
